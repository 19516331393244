import PageHeader from "../components/PageHeader";
import React, { useEffect} from 'react';
import {NotificationManager} from 'react-notifications';

const GuacamoleView = () => {
  
  useEffect(() => {
    NotificationManager.info('The game is deployed on a Microsoft Azure Virtual Machine!','Info!',10000);
    NotificationManager.info('You need a stable connection!','Info!',3000);
    NotificationManager.info('Username and password are mouhab!','Info!',20000);
  })
  
  return (
    <section className="portfolio">
      <PageHeader title="Pong Game" description="Live Link" />
      <iframe title="myFrame" sandbox="allow-scripts allow-popups allow-same-origin allow-forms " src="https://pong.francecentral.cloudapp.azure.com/guacamole/" width={1000} height={500}></iframe>
      <h6 className="pageTitle" style={{ fontSize: 30, marginTop: 50 }}>Username = Password = mouhab</h6>
    </section>

  );
};

export default GuacamoleView;
