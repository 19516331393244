import projectData from "./projectsData.json";
import Project from "../../components/Project";
import PageHeader from "../../components/PageHeader";
import {NotificationManager} from 'react-notifications';
import { useEffect } from 'react';

const Portfolio = () => {

  useEffect(() => {
    NotificationManager.info('Projects are under deployment!','Info!',10000);
    NotificationManager.info('For the moment, Live link is only working for the Pong Project!','Info!',10000);
  })

  const ProjectList = () =>
    projectData.map((project, i) => (
      <Project
        key={i}
        id={project.id}
        title={project.title}
        technologies={project.technologies}
        image={project.image}
        color={project.bgcolor}
        github={project.github}
        deployed={project.deployed}
        description={project.description}
      />
    ));

  return (
    <section className="portfolio">
      <PageHeader title="Portfolio" description="View my work" />
      <div className="row">
        <ProjectList />
      </div>
    </section>
  );
};

export default Portfolio;
