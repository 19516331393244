import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './notificationOverride.css'

function App() {
  const personalDetails = {
    name: "Mouhab Zitouni",
    location: "Courbevoie, FR",
    tagline: "I'm a Full Stack Developer",
    email: "mouhab.zitouni@gmail.com",
    availability: "Looking for an internship of 2-4 months",
    brand:
      "I am a full stack developer skilled in both front-end and back-end development. I have experience with various programming languages, databases, and frameworks, creating scalable and secure applications. My expertise in full-stack development makes me an asset to any project or team.",
  };

  return (
    <>
      <NotificationContainer/>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
